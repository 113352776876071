import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { ConexionApiService } from '../../conexion-api.service';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.scss'],
})

export class AyudaComponent implements OnInit {
  
  telefonocall:any = "tel:" + environment.contacto.telefonocall;
  @Input() name: string;

  constructor(
    private modalCtr: ModalController,
    public api: ConexionApiService
  ) { }

  ngOnInit() { }

  goToLink() {
    window.open(this.telefonocall);
  }

  ayudaWhatsappButton() {
    this.api.btnWspAyuda();
  }

  async close() {
    window.localStorage.setItem(
      "_cap_flag_ayuda",
      "0"
    );
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss(closeModal);
  }

}