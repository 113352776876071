import { AES, enc } from 'crypto-js';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
export class EncryptServiceService {
    constructor() {
        this.ssp = environment._ssp;
    }
    enc(phrase) {
        return AES.encrypt(phrase, this.ssp);
    }
    dec(phrase) {
        return AES.decrypt(phrase, this.ssp).toString(enc.Utf8).trim();
    }
    encJson(obj) {
        let objeto = JSON.stringify(obj);
        return this.enc(objeto);
    }
    decJson(obj) {
        let objeto = this.dec(obj);
        return JSON.parse(objeto);
    }
}
EncryptServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EncryptServiceService_Factory() { return new EncryptServiceService(); }, token: EncryptServiceService, providedIn: "root" });
