import { SwUpdate } from '@angular/service-worker';
import { timer } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
export class UpdateService {
    constructor(updates) {
        this.updates = updates;
        if (updates.isEnabled) {
            timer(0, 60000).subscribe(() => {
                updates.checkForUpdate().then(() => {
                    console.warn("[ - Update Check - ]");
                });
            });
        }
    }
    /** consulta por actualizaciones de la pwa */
    checkForUpdates() {
        this.updates.available.subscribe(e => {
            this.promptUser();
        });
    }
    /** inicia la actualización por reload  */
    promptUser() {
        console.warn("[ - Update encontrada - Actualizando - ]");
        this.updates.activateUpdate().then(() => {
            document.location.reload();
        });
    }
}
UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.ɵɵinject(i1.SwUpdate)); }, token: UpdateService, providedIn: "root" });
