import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { ConexionApiService } from '../../conexion-api.service';
export class AyudaComponent {
    constructor(modalCtr, api) {
        this.modalCtr = modalCtr;
        this.api = api;
        this.telefonocall = "tel:" + environment.contacto.telefonocall;
    }
    ngOnInit() { }
    goToLink() {
        window.open(this.telefonocall);
    }
    ayudaWhatsappButton() {
        this.api.btnWspAyuda();
    }
    close() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            window.localStorage.setItem("_cap_flag_ayuda", "0");
            const closeModal = "Modal Closed";
            yield this.modalCtr.dismiss(closeModal);
        });
    }
}
