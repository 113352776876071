import * as tslib_1 from "tslib";
import { Platform, NavController } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConexionApiService } from './conexion-api.service';
import { UpdateService } from './services/update.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { environment } from '../environments/environment';
import { ModalController } from '@ionic/angular';
import { AyudaComponent } from './modals/ayuda/ayuda.component';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
export class AppComponent {
    constructor(platform, device, nav, api, sw, router, locationStrategy, modalController) {
        this.platform = platform;
        this.device = device;
        this.nav = nav;
        this.api = api;
        this.sw = sw;
        this.router = router;
        this.locationStrategy = locationStrategy;
        this.modalController = modalController;
        this.telefono = environment.contacto.wsp;
        this.texto_contacto = environment.contacto.texto;
        this.telefonocall = "tel:" + environment.contacto.telefonocall;
        this.flag_ayuda = "";
        // block back button 
        this.preventBackButton();
        //  evitar install banner 
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
        });
        this.sw.checkForUpdates();
        // con esta funcion me aseguro de verificar que esté
        //  en un mobile durante toda la app
        if (this.device.isDesktop()) {
            this.nav.navigateRoot('/browser');
        }
        else {
            this.initializeApp();
        }
        // debug viewport
        let overlay = document.createElement('div');
        Storage.set({
            key: 'flag_ayuda',
            value: "0"
        });
        //this.api.presentToast("DPR:" +window.devicePixelRatio + " RES:" + window.innerWidth + "x" + window.innerHeight, 5);
    }
    AyudaModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.flag_ayuda = yield Storage.get({ key: 'flag_ayuda' });
            console.log(this.flag_ayuda.value);
            if (this.flag_ayuda.value == null || this.flag_ayuda.value == "0") {
                yield Storage.set({
                    key: 'flag_ayuda',
                    value: "1"
                });
                const modal = yield this.modalController.create({
                    component: AyudaComponent,
                    componentProps: {}
                });
                return yield modal.present();
            }
            else {
                yield Storage.set({
                    key: 'flag_ayuda',
                    value: "0"
                });
                const closeModal = "Modal Closed";
                yield this.modalController.dismiss(closeModal);
            }
        });
    }
    ayudaWhatsappButton() {
        this.api.btnWspAyuda();
    }
    preventBackButton() {
        history.pushState(null, null, location.href);
        this.locationStrategy.onPopState(() => {
            history.pushState(null, null, location.href);
        });
    }
    initializeApp() {
        // show app... don't touch
        this.platform.ready().then(() => {
            console.log("ready");
        });
    }
}
