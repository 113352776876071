<ion-app>

  <button id="floatcall" class="floatcall" (click)="AyudaModal()" >
   <img src="assets/imgs/call.svg">
  </button>


  

  <!--
    <a [href]="telefonocall" 
      class="floatcall" target="_blank">
      <ion-icon src="assets/imgs/call.svg"></ion-icon>
    </a>

    <a (click)="ayudaWhatsappButton()"
      class="floatwsp" target="_blank">
      <ion-icon src="assets/imgs/wsp.svg"></ion-icon>
    </a>
  -->
  
  <ion-router-outlet></ion-router-outlet>

  <div id="overlay-portrait">
    <div class="content">
      <ion-icon name="phone-portrait"></ion-icon>
      <h2>Su celular debe permanecer en posición vertical</h2>
    </div>
  </div>
</ion-app>